<template>
    <div>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            @created="created"
            @updated="updated"
            @cancelled="cancelled"
            @deleted="deleted"
        >
            <general-form-part v-model="form"></general-form-part>
        </base-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import routeType from '@/router/routeType'
import authType from '@/store/type/authType'
import ApiClientType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import FormExtend from '@/components/mixins/FormExtend'
import AuthMixin from '@/components/mixins/AuthMixin'
import GeneralFormPart from './GeneralFormPart'
import configType from '@/store/type/configType'

export default {
    components: {
        GeneralFormPart,
    },
    mixins: [FormExtend, AuthMixin],
    data() {
        return {
            api: ApiClientType.TAGS,
            mapper: MapperType.TAG_FORM,
            form: {
                image: null,
                translations: {},
                isFeatured: false,
                tagGroup: null,
            },
            editRoute: routeType.TAG_EDIT,
            listRoute: routeType.TAG_LIST,
        }
    },
    computed: {
        ...mapGetters({
            userId: authType.getters.USER_ID,
            currentLocale: configType.getters.CURRENT_LOCALE,
        }),
        title: function () {
            return this.form.translations[this.currentLocale]?.name
                ? this.form.translations[this.currentLocale].name
                : this.$t('labels.tag_edit')
        },
    },
}
</script>
