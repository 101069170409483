<template>
    <div>
        <tag-form></tag-form>
    </div>
</template>

<script>
import tagForm from '@/components/pages/tags/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        tagForm,
    },

    mixins: [dirtyForm],
}
</script>
