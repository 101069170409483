<template>
    <div>
        <tag-form :entity-id="entityId"></tag-form>
    </div>
</template>

<script>
import tagForm from '@/components/pages/tags/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: { tagForm },

    mixins: [dirtyForm],

    data() {
        return {
            entityId: null,
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                this.entityId = route.params.id
            },
        },
    },
}
</script>
